import { MainserviceService } from './../../../../../services/mainservice.service';
// Angular
import { Component, Input,ChangeDetectorRef, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
import * as Constants from'../../../../../services/api';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import {Router} from "@angular/router";

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	showProfile = true;
	profile_photo: any;
	fbprofile_photo:any;
	photo:any='';
profile:any=[];
	@Input() avatar: boolean = true;
	@Input() greeting: boolean = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	currentUser: any = { user:{} };

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>,	private router:Router,private ref: ChangeDetectorRef ,private mainserviceService:MainserviceService) {
		// this.mainserviceService.componentMethodCalled$.subscribe(
		// 	() => {
		// 		console.log("dscds");
		// 		this.ref.detectChanges();
		// 	  this.getprofile();
			
		// 	}
		//   );

		this.mainserviceService.componentMethodCalled$.subscribe(
			() => {
			  this.profile = localStorage.getItem('profile_image');
			  console.log(this.profile);
			//   this.userPhoto();
			}
		  );
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.showProfile = true;
		// this.ref.detectChanges();
		this.getprofile();
		// this.userPhoto();
		this.user$ = this.store.pipe(select(currentUser));
		console.log(this.greeting);
		this.currentUser = localStorage.getItem('user_detail');
	}
	userPhoto() {
		this.fbprofile_photo = this.mainserviceService.headerRefresh();
		console.log(this.fbprofile_photo);
	  }

	addNew(){
		this.router.navigate(['/listnav'])
	}

	
// 	onSubmit(event){
//     console.log(event.target.files[0]);
//     let file = event.target.files[0],photo:'';
//     this.mainserviceService.postSingleFileOperation(Constants.API_PATH.profile,file).then(data=>{
// 	  console.log(data);
// 	  this.mainserviceService.ProfileUpload();
// 	  this.ref.detectChanges();
// 		this.getprofile();
//       },error=>{
//         console.log(error);
//     });
//   }
  getprofile(){
	this.mainserviceService.getOperation(Constants.API_PATH.profiles).subscribe(
		data=>{
			console.log(data);
			
			this.profile=data.profile_picture+'?'+new Date();
			console.log(this.profile);
			localStorage.setItem('profile_image',this.profile);
			this.ref.detectChanges();
			// this.showProfile = false;
			// this.ref.detectChanges();
			// setTimeout(() => {
				// this.showProfile = true;
				// this.ref.detectChanges();
			// }, 100);
            
			// this.ref.detectChanges();
			// console.log(this.situation);
	},error =>{
		console.log(error);
	});
  }

	/**
	 * Log out
	 */
	logout() {
		console.log("abc");
		// this.store.dispatch(new Logout());
		localStorage.removeItem('user_token');
		localStorage.removeItem('user_detail');
		localStorage.removeItem('user_role');
		this.router.navigate(['/auth/login']);
	}

	selectProfilePhoto() {
		document.getElementById("selectedIDPhoto").click();
	}
}
