// Angular
import { AfterViewInit, Component, OnInit,ChangeDetectorRef } from '@angular/core';
import * as Constants from '../../../services/api';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { MainserviceService } from '../../../services/mainservice.service';

@Component({
	selector: 'kt-brand',
	templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
	// Public properties
	headerLogo: string;
	profile:{
		organization: any;
	};
	organization: any;
	headerStickyLogo: string;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-aside--minimize',
		togglerState: 'kt-aside__brand-aside-toggler--active'
	};

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(private layoutConfigService: LayoutConfigService,private cdr:ChangeDetectorRef, public htmlClassService: HtmlClassService,private MainserviceService:MainserviceService) {
	}

	
	ngOnInit(): void {
		this.headerLogo = this.layoutConfigService.getLogo();
		this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
		this.getProfile();
	}
	getProfile(){
		this.MainserviceService.getOperation(Constants.API_PATH.profiles).subscribe(
			data=>{
			  console.log(data);
			this.profile=data;
			this.cdr.detectChanges();
			console.log(this.profile);
				},error =>{
				console.log(error);
		});
	}
	/**
	 * On after view init
	 */
	ngAfterViewInit(): void {
	}
}
