import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { SnotifyService } from "ng-snotify";
import { AuthNoticeService, Login } from "../../../../core/auth";
import { AppState } from "../../../../core/reducers";
import { MainserviceService } from "../../../../services/mainservice.service";
import { finalize, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: "kt-two-factor",
	templateUrl: "./two-factor.component.html",
	styleUrls: ["./two-factor.component.scss"],
})
export class TwoFactorComponent implements OnInit {
	twoFactorForm: FormGroup;
	userData: any;
	loading: boolean;
	wrongCredential: boolean;
	private unsubscribe: Subject<any>;
	constructor(
		public fb: FormBuilder,
		public router: Router,
		public mainserviceService: MainserviceService,
		public cdr: ChangeDetectorRef,
		public snotifyService: SnotifyService,
		private store: Store<AppState>,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService
	) {
		console.log(this.router.getCurrentNavigation().extras.state);
		if (this.router.getCurrentNavigation().extras.state) {
			this.userData = this.router.getCurrentNavigation().extras.state.user;
		} else {
			this.router.navigateByUrl("/auth/login");
		}
	}

	ngOnInit() {
		this.initTwoFactorForm();
	}

	initTwoFactorForm() {
		this.twoFactorForm = this.fb.group({
			code: ["", Validators.compose([Validators.required])],
		});
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.twoFactorForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	send() {
		const controls = this.twoFactorForm.controls;
		/** check form */
		if (this.twoFactorForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		let postData = {
			user: {
				email: this.userData.email,
				password: this.userData.password,
				code: controls["code"].value,
			},
		};
		this.wrongCredential = false;
		this.mainserviceService.login(postData).subscribe(
			(data) => {
				if (data.error_status) {
					this.loading = false;
					this.cdr.markForCheck();
					this.snotifyService.error(data.errors.message);
				} else if (data.user) {
					this.store.dispatch(
						new Login({ authToken: data.user.token })
					);
					localStorage.setItem("user_token", data.user.token);
					localStorage.setItem("user_detail", JSON.stringify(data.user));
					localStorage.setItem("user_role", data.user.role);

					this.router.navigateByUrl("/dashboard");
				} else {
					this.loading = false;
					this.wrongCredential = true;
					this.authNoticeService.setNotice(
						this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
						"danger"
					);
				}
				takeUntil(this.unsubscribe),
					finalize(() => {
						this.loading = false;
						this.cdr.markForCheck();
					});
			},
			(error) => {
				takeUntil(this.unsubscribe),
					finalize(() => {
						this.loading = false;
						this.cdr.markForCheck();
					});
				this.loading = false;
				this.wrongCredential = true;
				this.cdr.markForCheck();
			}
		);
	}
}
