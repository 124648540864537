export const API_URL = 'https://backend.inoib.com/api/v1/';
// export const API_URL = 'http://localhost:8000/api/v1/';
export const API_PATH = {
    'notification':'users/notifications',
    'category':'users/category',
    'masters':'users/masters',
    'situations':'users/situations',
    'getcollaborate':'users/situations?collaborated=true',
    'getshared':'users/situations?shared=true',
    'getarchived':'users/situations?archived=true',
    'profile':'users/profile/picture', //for image post
    'profiles':'users/profile', //for get images
    'two_factor':'users/two-factor', //for two factor api
    'users':'users/users', //for get all users
    'collaborate':'collaborator',
    'statistics':'users/statistics'

}