import { Injectable } from '@angular/core';
import { Observable, throwError,Subject } from "rxjs";
import { map, retry, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Constants from "./api";

@Injectable({
  providedIn: 'root'
})
export class MainserviceService {
    categoryid:any={};
    situation:any;
    dashboardCardData:any;
    currentUser:any;
    profile_photo: any;
    profile_image:any;
    private componentMethodCallSource = new Subject<any>();
    componentMethodCalled$ = this.componentMethodCallSource.asObservable();
    constructor(private http: HttpClient, public router: Router) { }

  public getOperation(LOGIN_API_URL: any): Observable<any> {
    console.log(localStorage.getItem('user_token'));
    const headers = new HttpHeaders({ 'Authorization': localStorage.getItem('user_token') ? 'Token ' + localStorage.getItem('user_token') : '', });
    return this.http.get(this.getApiUrl() + LOGIN_API_URL, { headers: headers })
      .pipe(
        map(this.extractJsonData)
      )
      .pipe(
        catchError(this.handleJsonError)
      );
  }
  /**without authorization */
  public getOperations(LOGIN_API_URL: any): Observable<any> {
    return this.http.get(this.getApiUrl() + LOGIN_API_URL)
      .pipe(
        map(this.extractJsonData)

      )
      .pipe(
        catchError(this.handleJsonError)
      );
  }
  
  handleJsonError(error: any) {
    console.log(error);
    // this.mainerror = error;
    // if (error) {
    //   // const errMsg = (error.error) ? error.error :
    //   // error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    //   try {
    //     JSON.parse(error);
    //   } catch (e) {
    //     return throwError(error);cardetail
    //   }
    //   return throwError(JSON.parse(error));
    // }
    return throwError(JSON.parse(JSON.stringify(error)));
  }
  
  private extractJsonData(response: Response) {
    console.log(response);
    let data:any  = response;
    if(data.errors){
      if(data.errors.message == 'jwt expired'){
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_detail');
        localStorage.removeItem('user_role');
        // this.redirectToLogin();
        window.location.reload();
      }
      else {
        return data;
      }
    }
    else{
      return data;
    }
  }
  protected getApiUrl() {
    return Constants.API_URL;
  
  }
  public postOperation(API_URL: any, objectData: any): Observable<any> {
      console.log(localStorage.getItem('user_token'));
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('user_token') ? 'Token ' + localStorage.getItem('user_token') : '',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.post(this.getApiUrl() + API_URL, objectData, { headers: headers })
      .pipe(
        map(this.extractJsonData)
      )
      .pipe(
        catchError(this.handleJsonError)
      );
  }
  public putOperation(API_URL, objectData): Observable<any> {
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('user_token') ? 'Token ' + localStorage.getItem('user_token') : '',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.put(this.getApiUrl() + API_URL, objectData, { headers: headers })
      .pipe(
        map(this.extractJsonData)
      )
      .pipe(
        catchError(this.handleJsonError)
      );
  }
  login(data) {
    const API_URL = 'users/login';
    return this.postOperation(API_URL, data);
  }
  forget(data) {
      const API_URL = 'users/forgot';
      return this.postOperation(API_URL, data);
  }
  public postSingleFileOperation(API_URL: any, file: any): Promise<any> {
    console.log(file);
    const headers = new HttpHeaders({
      // "Content-Type": "multipart/form-data",
      'Authorization': localStorage.getItem('user_token') ? 'Token ' + localStorage.getItem('user_token') : '',
      'X-Requested-With': 'XMLHttpRequest'
    });
      headers.append('Content-Type', 'application/json');
      headers.append('Content-Type', 'multipart/form-data; charset=utf-8;');
      const formData = new FormData();

      // formData.append('photo', file, file.name);
      if(file.photo){
        console.log('hiii');
        // file.photo = '';
        formData.append('photo', file.photo, file.photo.name);
        }
      let options = { headers: headers };

      options.headers.delete("Content-Type");

      return this.http.post(this.getApiUrl() + API_URL, formData, options)
        .toPromise()
      .catch((e) => {
        // handle me
      });
  }

  public deleteOperation(API_URL): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('user_token') ? 'Token ' + localStorage.getItem('user_token') : '',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.delete(this.getApiUrl() + API_URL, { headers: headers })
      .pipe(
        map(this.extractJsonData)
      )
      .pipe(
        catchError(this.handleJsonError)
      );
  }

  ProfileUpload() {
    this.componentMethodCallSource.next();
}
changePassword(data){
    const API_URL = 'users/reset';
    return this.postOperation(API_URL,data);
}
refreshToken() {
  const API_URL = 'users/token/refresh';
  return this.getOperation(API_URL);
}

getProfilePhoto(profile) {
  this.profile_photo = profile.profiledp;
}

headerRefresh(){
  this.profile_image = localStorage.getItem('profile_image');
  this.componentMethodCallSource.next();
  // if(this.currentUser && JSON.parse(this.currentUser).photoUrl){
  //   this.profile_photo = JSON.parse(this.currentUser).photoUrl;
  //   console.log(this.profile_photo);
  //   return this.profile_photo;
  // }
  return this.profile_image;
  }

  downloadPdf(apiUrl){
    //document.location.href = this.getApiUrl()+apiUrl+'&token='+(localStorage.getItem('user_token') ? 'Token ' + localStorage.getItem('user_token') : '');
    window.open(this.getApiUrl()+apiUrl+'&token='+(localStorage.getItem('user_token') ? localStorage.getItem('user_token') : ''), "_blank");
  }


}
