import { Collaboratemodule } from './collaborate/collaborate.module';

// Angular
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{path: 'reset',loadChildren: () => import('app/reset-password/reset-password.module').then(m => m.resetPassword)},

	{ 
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'collaborate/:id',
				loadChildren: () => import('app/collaborate/collaborate.module').then(m => m.Collaboratemodule)
			},
			{
				path: 'share/:id',
				loadChildren: () => import('app/share/share.module').then(m => m.ShareModule)
			},
			{
				path: 'listnav',
				loadChildren: () => import('app/listnav/listnav.module').then(m => m.Listnavmodule)
			},
			{
				path: 'situationadd',
				loadChildren: () => import('app/situationadd/situationadd.module').then(m => m.Situationaddmodule)
			},
			{
				path: 'listnav1/:id',
				loadChildren: () => import('app/listnav1/listnav1.module').then(m => m.Listnav1module)
			},
			{
				path: 'news-notification',
				loadChildren: () => import('app/news-notification/news-notification.module').then(m => m.NewsNotificationModule)
			},
			
			{
				path: 'news',
				loadChildren: () => import('app/news/news.module').then(m => m.Listnavmodule)
			},
			{
				path:'profile',
				loadChildren:() => import('app/profile/profile.module').then(m=>m.Profilemodule)
			},
			{
				path:'settings',
				loadChildren:() => import('app/settings/settings.module').then(m=>m.Settingmodule)
			},
			{
				path:'privacy',
				loadChildren:() => import('app/privacy/privacy.module').then(m=>m.Privacymodule)
			},
			{
				path:'t&c',
				loadChildren:() => import('app/terms/terms.module').then(m=>m.Termsmodule)
			},
			{
				path:'updateEmail',
				loadChildren:() => import('app/update-email/update-email.module').then(m=>m.UpdateEmailmodule)
			},
			{
				path:'pdf/:type',
				loadChildren: () => import('app/download-pdf/download-pdf.module').then(m => m.DownloadPdfmodule)
				
			},
			{
				path:'colab/:type',
				loadChildren: () => import('app/all-colab/all-colab.module').then(m => m.AllColabmodule)
				
			},
			{
				path: 'mail',
				loadChildren: () => import('app/views/pages/apps/mail/mail.module').then(m => m.MailModule)
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('app/views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			{
				path: 'ngbootstrap',
				loadChildren: () => import('app/views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
			},
			{
				path: 'material',
				loadChildren: () => import('app/views/pages/material/material.module').then(m => m.MaterialModule)
			},
			{
				path: 'user-management',
				loadChildren: () => import('app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},
			
			{
				path: 'wizard',
				loadChildren: () => import('app/views/pages/wizard/wizard.module').then(m => m.WizardModule)
			},
			{
				path: 'builder',
				loadChildren: () => import('app/views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'two-factor',
				loadChildren: () => import('app/two-fa/two-fa.module').then(m => m.TwoFaModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					'type': 'error-v6',
					'code': 403,
					'title': '403... Access forbidden',
					'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},

			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
			{path:'tablelist',redirectTo: 'TablelistComponent'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
